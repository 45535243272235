<div style="height: 84px" class="bg-gray">
    <div class="flex items-center justify-center pt-20">
        <input class="flex searchInput" matInput [formControl]="searchTerm" type="text"
               placeholder="Account Number" autocomplete="off">
        <button (click)="clearInput()" class="-mx-24 pt-6" aria-label="close">
            <mat-icon fontSet="material-symbols-outlined" style="-webkit-text-fill-color: rgb(0, 120, 158)">close
            </mat-icon>
        </button>
    </div>

    <div class="flex items-center justify-center" style="color:red;" *ngIf="searchTerm.invalid">
        {{ errorMessage() }}
    </div>
</div>
@if (!loadingCompleted) {
    <div class="spinner">
        <mat-spinner [diameter]="45"></mat-spinner>
    </div>
}

<div class="sections">
    @if (accountFound) {
        <div class="flex justify-between mt-10 mb-10 items-center">
            <div class="ml-10 flex justify-center w-2/3">
                <mat-card appearance="outlined" class="w-1/2 mt-10">
                    <mat-card-header class="bg-gray" style="height: 36px;padding-top: 2px">
                        <mat-card-title style="padding-bottom: 12px">Account Details</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="flex-1">
                        <table>
                            <tr>
                                <td class="font-bold px-20">Account Number:</td>
                                <td class="px-64">{{accountNumber}}</td>
                            </tr>
                            <tr>
                                <td class="font-bold px-20">Jurisdiction:</td>
                                <td class="px-64">{{header.jurisdiction}}</td>
                            </tr>
                            <tr>
                                <td class="font-bold px-20">Rate:</td>
                                <td class="px-64 underline" style="color: rgb(0, 120, 158)">
                                    <a href="https://www.duke-energy.com/Home/Billing/Rates" target="_blank">
                                        {{header.service_agreement_price_nm}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-bold px-20">State:</td>
                                <td class="px-64">{{header.charge_state_cd}}</td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="w-1/4">
                <mat-form-field>
                    <mat-label>Select Bill</mat-label>
                    <mat-select (selectionChange)="selectInvoice($event.value)">
                        @for (invoice of invoices; track invoice) {
                            <mat-option [value]="invoice">{{ getMonth(invoice.month) }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mx-20 pl-20 pr-20 flex items-center italic" style="color: red">Please note: This tool does not
            include
            state and local taxes, and products and services charges associated with Duke Energy's non-regulated
            business. The actual total billed amount will be higher than the total amount shown below.
        </div>
        <section>
            <div>
                <div class="table--header">
                    <div class="table--header-row">
                        <div class="table--cell-rider-name left">Charge</div>
                        <div class="table--cell left">Date</div>
                        <div class="table--cell left">Units</div>
                        <div class="table--cell left">SORA</div>
                        <div class="table--cell right">Cost/Unit</div>
                        <div class="table--cell right">Total Cost</div>
                    </div>
                </div>
                <div class="table-account-container">
                    @for (item of lines(); track item) {
                        <div class="table--row">
                            <div class="table--cell-rider-name left">{{ item.source_charge_rate_description }}</div>
                            <div class="table--cell left" style="padding-right: 15px">{{ item.daterange }}</div>
                            <div class="table--cell left">{{ +item.billed_quantity | number }}</div>
                            <div class="table--cell left"><i *ngIf="item.bucket" class="fa fa-check"
                                                             style="color: deepskyblue"></i></div>
                            <div class="table--cell right"> {{ getCostPerUnit(item) }}</div>
                            <div class="table--cell right">{{ item.dollar_amount | currency }}</div>
                        </div>
                    }
                    <div class="table--row bg-gray total-charged">
                        <div class="table--cell-rider-name font-bold left">Total Charges</div>
                        <div class="table--cell left"></div>
                        <div class="table--cell left"></div>
                        <div class="table--cell left"></div>
                        <div class="table--cell right"></div>
                        <div class="table--cell right">{{ totalCharges | currency }}</div>
                    </div>
                </div>
            </div>
        </section>
    } @else {
        <div class="mt-20 box">
            <div class="oval-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="32"
                     height="32" viewBox="0 0 32 32">
                    <circle r="16" cx="16" cy="16" fill="rgb(158, 27, 50)"></circle>
                    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="20"
                          fill="white">!
                    </text>
                </svg>
            </div>

            <h2 class="text-red text-center">Account Not Found</h2>
            <p class="text-center">{{ accountNotFoundExceptionMessage }}</p>
        </div>
    }
</div>

