import {AfterViewInit, Component, inject, OnDestroy, OnInit, signal} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {AsyncPipe, CurrencyPipe, DecimalPipe, NgIf} from "@angular/common";
import {Header} from "../../../models/header";
import {UsageAlertsHttpService} from "../../../httpServices/usage-alerts.http-service";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {Line} from "../../../models/line";
import {debounceTime, EMPTY, of, Subject, switchMap} from "rxjs";
import {MatOption, MatSelect} from "@angular/material/select";
import {Invoice} from "../../../models/invoice";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {catchError} from "rxjs/operators";

@Component({
    selector: 'app-search',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        MatError,
        NgIf,
        CurrencyPipe,
        AsyncPipe,
        MatIconModule,
        MatProgressSpinner,
        DecimalPipe,
        MatSelect,
        MatOption,
        MatCard,
        MatCardContent,
        MatCardHeader,
        MatCardTitle
    ],
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
    totalRiders = '';
    totalElectricCharges = '';
    totalCharges = '';
    searchTerm = new FormControl('', [
        Validators.pattern(/^91\d{10}$/),
        Validators.required
    ]);
    private onDestroy = new Subject<void>();
    accountId: number = 0;
    accountNumber = '';
    errorMessage = signal('');
    header: Header = {} as Header;
    lines = signal<Line[]>([]);
    invoices: Invoice[] = [];
    service = inject(UsageAlertsHttpService);
    accountFound = true;
    loadingCompleted = true;
    accountNotFoundExceptionMessage = '';
    private destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.searchTerm?.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((value: string | null) => {
                    if (this.searchTerm.valid) {
                        this.accountId = +value!;
                        this.loadingCompleted = false;
                        return this.service.getUsageAlerts(this.accountId)
                            .pipe(
                            catchError(() => {
                                this.errorMessage.set('Search Term must start with 91 and be 12 characters long');
                                this.loadingCompleted = true;
                                this.accountFound = false;
                                return EMPTY;
                            }));
                    } else {
                        this.errorMessage.set('Search Term must start with 91 and be 12 characters long');
                        this.clearAllDetails();
                        return EMPTY;
                    }
                }),
            )
            .subscribe((result) => {
                    this.accountNumber = this.accountId.toString();
                    this.lines.set(result['lines']);
                    this.header = result['header'][0];
                    this.invoices = result['invoices'].map((data: any[]) => ({
                        month: data[0],
                        invoiceId: data[1],
                    }));
                    this.accountFound = true;
                    this.loadingCompleted = true;
                    this.totalCharges = this.header.bill_new_charge_dollars.toString();
                },
                (e) => {
                    this.accountNotFoundExceptionMessage = e.statusText;
                    this.accountFound = true;
                    this.loadingCompleted = true;
                });
    }

    clearInput() {
        this.searchTerm.setValue('');
        this.clearAllDetails();
    }

    private clearAllDetails() {
        this.header = {} as Header;
        this.lines.set([]);
        this.invoices = [];
        this.totalElectricCharges = '';
        this.totalRiders = '';
        this.totalCharges = '';
        this.loadingCompleted = true;
        this.accountFound = true;
        this.accountNumber = '';
    }

    getCostPerUnit(rider: Line): string {
        if (+rider.billed_quantity !== 0) {
            return (+rider.dollar_amount / +rider.billed_quantity).toFixed(6);
        }
        return '';
    }

    ngAfterViewInit(): void {
        const svg = document.querySelector("path");
        if (svg) {
            svg.setAttribute('transform', 'scale(0.4)');
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }

    selectInvoice(value: Invoice): void {
        this.loadingCompleted = false;
        this.lines.set([]);
        this.totalCharges = '';
        const invoiceParameter = value.month + "-" + value.invoiceId;
        this.service.getBillByAccountIdAndInvoiceId(this.accountId, invoiceParameter)
            .subscribe((value) => {
                    this.lines.set(value['lines']);
                    this.header = value['header'][0];
                    this.accountFound = true;
                    this.loadingCompleted = true;
                    this.totalCharges = this.header.bill_new_charge_dollars.toString();
                },
                (e) => {
                    this.accountNotFoundExceptionMessage = e.statusText;
                    this.accountFound = false;
                    this.loadingCompleted = true;
                });
    }

    getMonth(month: string) {
        return month.substring(0, 4) + '-' + month.substring(4, 6);
    }
}
